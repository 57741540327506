import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Icon,
  Calendar,
  DateRangeType,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogFooter,
  DialogType,
  Checkbox,
  Text,
  DatePicker
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

function toFixedTrunc(x, n) {
  x = toFixed(x);

  // From here on the code is the same than the original answer
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`;
}

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

function hasSpecificValue(obj, targetValue) {
  // Check if the object is valid
  if (obj && typeof obj === 'object') {
      // Iterate through object keys
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              // Check if the value includes the target value (case-insensitive)
              const lowercaseValue = typeof obj[key] === 'string' ? obj[key].toLowerCase() : '';
              if (lowercaseValue.includes(targetValue)) {
                  return true;
              }
          }
      }
  }
  return false;
}

function getValue(obj, targetValue) {
  // Check if the object is valid
  if (obj && typeof obj === 'object') {
    // Iterate through object keys
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Check if the value includes the target value (case-insensitive)
        const lowercaseValue = typeof obj[key] === 'string' ? obj[key].toLowerCase() : '';
        if (lowercaseValue.includes(targetValue)) {
          // Return the matching value if found
          return obj[key];
        }
      }
    }
  }
  // Return null if no match is found
  return null;
}

const findValueNextToBranch = (address) => {
  // Regular expression to match the pattern "สาขา", "Branch", "branch", or "BRANCH" followed by a number
  const branchNumberRegex = /(สาขา|Branch|branch|BRANCH)\s+(\d+)/i;

  // Test if the address matches the pattern
  const matchResult = address.match(branchNumberRegex);

  // Return only the number part from the matchResult or null if no match
  return matchResult ? matchResult[2] : null;
};

function find13DigitNumber(str) {
  // Regular expression to match exactly 13 digits in a row
  const regex = /\b\d{13}\b/;
  const match = str.match(regex);
  return match ? match[0] : null;
}

function isCompanyName(str) {
  // ปรับปรุง pattern ให้ครอบคลุมรูปแบบต่างๆ
  const companyNamePattern = /(Ltd|Limited|Co|Corporation|Inc|Corp|LLC|GmbH|S\.A\.|Pty|PLC|จำกัด|มหาชน|บริษัท|CO\.|LTD\.)\s*(\([\w\s\u0E00-\u0E7F]+\))?$/i;
  return companyNamePattern.test(str);
}

function getSortByAndSortDirection(primarySort) {
  if (primarySort === "documentDate") {
    return "TxnDate"
  } else if (primarySort === "number") {
    return "DocNumber"
  }

  return "TxnDate"
}

const QuickBooks = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { t, i18n } = useTranslation();

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("quickbook-pageSize") * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('quickbook-currentPage') * 1 || 1);

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [isLoading, setIsLoading] = useState(false);

  const [registerVat, setRegisterVat] = useState(true);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('quickbook-primarySort') || 'createdTime');

  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('quickbook-sort-documentDate') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('quickbook-sort-number')) {
      if (sessionStorage.getItem('quickbook-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [sortDirection, setSortDirection] = useState(sessionStorage.getItem('quickbook-sortDirection') || 'DESC');

  function getPageText(pageNumber) {
    return t("quickBook.page") + " " + pageNumber
  }

  const totalPage = 100

  useEffect(() => {
    let _pageDropdown = [];

    for (let i = 1; i <= totalPage; i++) {
      _pageDropdown.push({
          key: i,
          text: getPageText(i),
          iconProps: { iconName: "Page" },
          onClick: () => {
            console.log("Selected page: " + i);
            setCurrentPage(i);
          },
      });
    }

    setPageDropdown(_pageDropdown);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const DayPickerStrings = {
    months: [
      t('quickBook.months01'),
      t('quickBook.months02'),
      t('quickBook.months03'),
      t('quickBook.months04'),
      t('quickBook.months05'),
      t('quickBook.months06'),
      t('quickBook.months07'),
      t('quickBook.months08'),
      t('quickBook.months09'),
      t('quickBook.months10'),
      t('quickBook.months11'),
      t('quickBook.months12'),
    ],

    shortMonths: [
      t('quickBook.shortMonths01'),
      t('quickBook.shortMonths02'),
      t('quickBook.shortMonths03'),
      t('quickBook.shortMonths04'),
      t('quickBook.shortMonths05'),
      t('quickBook.shortMonths06'),
      t('quickBook.shortMonths07'),
      t('quickBook.shortMonths08'),
      t('quickBook.shortMonths09'),
      t('quickBook.shortMonths10'),
      t('quickBook.shortMonths11'),
      t('quickBook.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('quickBook.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('quickBook.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('quickBook.months01'),
      t('quickBook.months02'),
      t('quickBook.months03'),
      t('quickBook.months04'),
      t('quickBook.months05'),
      t('quickBook.months06'),
      t('quickBook.months07'),
      t('quickBook.months08'),
      t('quickBook.months09'),
      t('quickBook.months10'),
      t('quickBook.months11'),
      t('quickBook.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("quickbook-queryMonth-key") && {
      key: sessionStorage.getItem("quickbook-queryMonth-key"),
      text: sessionStorage.getItem("quickbook-queryMonth-text"),
      ...(sessionStorage.getItem("quickbook-queryMonth-type") ? { type: sessionStorage.getItem("quickbook-queryMonth-type") } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('documents.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("quickbook-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("quickbook-fromDate") ? new Date(sessionStorage.getItem("quickbook-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("quickbook-toDate") ? new Date(sessionStorage.getItem("quickbook-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [date, setDate] = useState(sessionStorage.getItem('quickbook-date') ? new Date(sessionStorage.getItem('quickbook-date')) : new Date());

  const [from, setFrom] = useState(sessionStorage.getItem('quickbook-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('quickbook-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [pageDropdown, setPageDropdown] = useState([]);

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('quickBook.thisMonth');

    let monthNames = [
      t('quickBook.months01'),
      t('quickBook.months02'),
      t('quickBook.months03'),
      t('quickBook.months04'),
      t('quickBook.months05'),
      t('quickBook.months06'),
      t('quickBook.months07'),
      t('quickBook.months08'),
      t('quickBook.months09'),
      t('quickBook.months10'),
      t('quickBook.months11'),
      t('quickBook.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);
                          
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem("quickbook-queryMonth-type", "dateRange");
                          sessionStorage.setItem("quickbook-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                          sessionStorage.setItem("quickbook-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                          setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("quickbook-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem("quickbook-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("quickbook-fromDate", fromDate.toISOString());
                          sessionStorage.setItem("quickbook-toDate", toDate.toISOString());

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);

                          setCurrentPage(1);

                          setSearch("");
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
                <Calendar
                  onSelectDate={(_date) => {
                    let monthNames = [
                      t('quickBook.months01'),
                      t('quickBook.months02'),
                      t('quickBook.months03'),
                      t('quickBook.months04'),
                      t('quickBook.months05'),
                      t('quickBook.months06'),
                      t('quickBook.months07'),
                      t('quickBook.months08'),
                      t('quickBook.months09'),
                      t('quickBook.months10'),
                      t('quickBook.months11'),
                      t('quickBook.months12'),
                    ];

                    setDate(_date);

                    sessionStorage.setItem('quickbook-date', _date.toISOString());

                    let adjustDate = _date;

                    let year = adjustDate.getFullYear() + 543;

                    setQueryMonth({
                      key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                      text: monthNames[adjustDate.getMonth()] + ' ' + year,
                    });

                    sessionStorage.setItem("quickbook-queryMonth-type", "");
                    sessionStorage.setItem(
                      'quickbook-queryMonth-key',
                      adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                    );
                    sessionStorage.setItem('quickbook-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                    setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('quickbook-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('quickbook-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setCurrentPage(1);

                    setSearch("");
                  }}
                  isMonthPickerVisible={true}
                  highlightSelectedMonth={true}
                  isDayPickerVisible={false}
                  dateRangeType={DateRangeType.Day}
                  autoNavigateOnSelection={false}
                  showGoToToday={false}
                  value={date}
                  strings={DayPickerStrings}
                  showSixWeeksByDefault={true}
                />
              )}
            </div>
          );
        },
      },
    },
    {
      key: "pageSize " + pageSize,
      text: pageSize + " " + t("quickBook.documentPerPage"),
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "NumberedList" },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
          items: [
              {
                  key: "pageSize " + 10,
                  text: "10 " + t("quickBook.documentPerPage"),
                  iconProps: { iconName: "NumberedList" },
                  onClick: () => {
                      console.log("10 ออเดอร์/หน้า");
                      setPageSize(10);
                      sessionStorage.setItem("quickbook-pageSize", 10);

                      setSearch("");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                  },
              },
              {
                  key: "pageSize " + 20,
                  text: "20 " + t("quickBook.documentPerPage"),
                  iconProps: { iconName: "NumberedList" },
                  onClick: () => {
                      console.log("20 ออเดอร์/หน้า");
                      setPageSize(20);
                      sessionStorage.setItem("quickbook-pageSize", 20);

                      setSearch("");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                  },
              },
              {
                  key: "pageSize " + 30,
                  text: "30 " + t("quickBook.documentPerPage"),
                  iconProps: { iconName: "NumberedList" },
                  onClick: () => {
                      console.log("30 ออเดอร์/หน้า");
                      setPageSize(30);
                      sessionStorage.setItem("quickbook-pageSize", 30);

                      setSearch("");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                  },
              },
              {
                  key: "pageSize " + 40,
                  text: "40 " + t("quickBook.documentPerPage"),
                  iconProps: { iconName: "NumberedList" },
                  onClick: () => {
                      console.log("40 ออเดอร์/หน้า");
                      setPageSize(40);
                      sessionStorage.setItem("quickbook-pageSize", 40);

                      setSearch("");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                  },
              },
              {
                  key: "pageSize " + 50,
                  text: "50 " + t("quickBook.documentPerPage"),
                  iconProps: { iconName: "NumberedList" },
                  onClick: () => {
                      console.log("50 ออเดอร์/หน้า");
                      setPageSize(50);
                      sessionStorage.setItem("quickbook-pageSize", 50);

                      setSearch("");

                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                  },
              },
          ],
      },
    },
    {
      key: currentPage,
      text: t('quickBook.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('quickBook.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setSearch("");
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('quickBook.disconnect'),
      onClick: () => {
        console.log('quickbook disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('quickBook.disconnect'),
          message: t('quickBook.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
      disabled: !loadDataComplete || isCreateDocument || search,
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let taxCodeList = [];
    let taxRateList = [];

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/quickbooks/tax-codes', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            taxCodeList = response.data.TaxCode;
            console.log(taxCodeList);
          });

        await axios
          .get(API_URL + '/sources/quickbooks/tax-rates', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            taxRateList = response.data.TaxRate;
            console.log(taxRateList);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        // instance.acquireTokenRedirect(silentRequest);
        setIsLoading(false);
      });

    let documentPath = '/etax/documents/receipts/confirm';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices/confirm';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb/confirm';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts/confirm';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices/confirm';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices/confirm';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices/confirm';
        break;
      default:
        break;
    }

    let d = item.documentDate.split('-');

    let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _dueDate = ""
    let _dueDateBE = ""
    let _dueDateCE = ""

    let dueD = item.dueDate ? item.dueDate.split('-') : ""
    if (dueD) {
      _dueDate = new Date(dueD[2] + '/' + dueD[1] + '/' + dueD[0]);
      _dueDateBE = `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);
      _dueDateCE = `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();
    }


    let _taxId = 'N/A';
    let _taxNumberType = 'OTHR';
    let _branchNumber = '';
    let _branchText = '';

    let _customer = {
      addressLineOne: item.customerAddress,
      addressLineTwo: '',
      branchNumber: _branchNumber,
      branchText: _branchText,
      email: item.customerEmail,
      name: item.customerName,
      phone: '',
      phoneCountryCode: '+66',
      postcode: item.customerPostcode ? item.customerPostcode : '00000',
      taxId: _taxId,
      taxNumberType: _taxNumberType,
      language: 'th',
      unstructure: true,
    };

    let _items = [];

    let _totalBeforeDiscount = 0;
    let _totalItemDiscount = 0;
    let _discount = 0;

    let _vatPriceTotal = 0;
    let _vatPriceTotalBeforeDiscount = 0;
    let _vatPriceDiscountTotal = 0;

    let _zeroVatPriceTotal = 0;
    let _zeroVatPriceTotalBeforeDiscount = 0;
    let _zeroVatPriceDiscountTotal = 0;

    let _nonVatPriceTotal = 0;
    let _nonVatPriceTotalBeforeDiscount = 0;
    let _nonVatPriceDiscountTotal = 0;

    for (let i = 0; i < item.items.length; i++) {
      if (item.items[i].Amount) {
        if (item.items[i].Id) {
          let _quantity = item.items[i].SalesItemLineDetail.Qty;
          let _price = Math.round(item.items[i].SalesItemLineDetail.UnitPrice * 100) / 100;
  
          let _total = Math.round(_price * _quantity * 100) / 100;
  
          let _taxCode = item.items[i].SalesItemLineDetail.TaxCodeRef?.value;
  
          let _percentVat;
          let _percentVatText;
  
          if (_taxCode) {
            let taxCodeObj = taxCodeList.find((_taxCodeObj) => _taxCodeObj.Id === _taxCode);
  
            const taxRateDetailLength = taxCodeObj.SalesTaxRateList.TaxRateDetail.length;
            let taxRateObj = taxRateList.find(
              (_taxRateObj) => _taxRateObj.Id === taxCodeObj.SalesTaxRateList.TaxRateDetail[taxRateDetailLength - 1].TaxRateRef.value
            );
  
            const EffectiveTaxRateList = taxRateObj.EffectiveTaxRate;
  
            const taxRate = EffectiveTaxRateList[EffectiveTaxRateList.length - 1].RateValue;
  
            if (taxRate > 0) {
              _percentVat = percentVat;
              _percentVatText = percentVatText;
  
              if (item.items[i].Amount >= 0) {
                _totalBeforeDiscount = _totalBeforeDiscount + item.items[i].Amount;
                _vatPriceTotalBeforeDiscount = _vatPriceTotalBeforeDiscount + item.items[i].Amount;
  
                let _description = item.items[i].Description

                if (item.items[i].SalesItemLineDetail?.ItemRef?.name) {
                  _description = item.items[i].SalesItemLineDetail?.ItemRef?.name + (item.items[i].Description ? (" : " + item.items[i].Description) : "")
                }

                _items.push({
                  number: i + 1 + '',
                  // description: item.items[i].SalesItemLineDetail.ItemRef.name,
                  description: _description,
                  quantity: _quantity,
                  unitCode: '',
                  unitName: '-',
                  price: _price,
                  discount: 0,
                  total: _total,
                  percentVat: _percentVat,
                  percentVatText: _percentVatText,
                });
              } else if (item.items[i].Amount < 0) {
                _totalItemDiscount = _totalItemDiscount + Math.abs(item.items[i].Amount);
  
                _vatPriceDiscountTotal = _vatPriceDiscountTotal + Math.abs(item.items[i].Amount);
              }
            } else {
              if (taxCodeObj.Name.includes('Exempt') || taxCodeObj.Name.includes('No VAT')) {
                _percentVat = nonVat;
                _percentVatText = nonVatText;
  
                if (item.items[i].Amount >= 0) {
                  _totalBeforeDiscount = _totalBeforeDiscount + item.items[i].Amount;
                  _nonVatPriceTotalBeforeDiscount = _nonVatPriceTotalBeforeDiscount + item.items[i].Amount;

                  let _description = item.items[i].Description

                  if (item.items[i].SalesItemLineDetail?.ItemRef?.name) {
                    _description = item.items[i].SalesItemLineDetail?.ItemRef?.name + " : " + item.items[i].Description
                  }
  
                  _items.push({
                    number: i + 1 + '',
                    // description: item.items[i].SalesItemLineDetail.ItemRef.name,
                    description: _description,
                    quantity: _quantity,
                    unitCode: '',
                    unitName: '-',
                    price: _price,
                    discount: 0,
                    total: _total,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                } else if (item.items[i].Amount < 0) {
                  _totalItemDiscount = _totalItemDiscount + Math.abs(item.items[i].Amount);
  
                  _nonVatPriceDiscountTotal = _nonVatPriceDiscountTotal + Math.abs(item.items[i].Amount);
                }
              } else {
                _percentVat = zeroVat;
                _percentVatText = zeroVatText;
  
                if (item.items[i].Amount >= 0) {
                  _totalBeforeDiscount = _totalBeforeDiscount + item.items[i].Amount;
                  _zeroVatPriceTotalBeforeDiscount = _zeroVatPriceTotalBeforeDiscount + item.items[i].Amount;
  
                  let _description = item.items[i].Description

                  if (item.items[i].SalesItemLineDetail?.ItemRef?.name) {
                    _description = item.items[i].SalesItemLineDetail?.ItemRef?.name + " : " + item.items[i].Description
                  }

                  _items.push({
                    number: i + 1 + '',
                    // description: item.items[i].SalesItemLineDetail.ItemRef.name,
                    description: _description,
                    quantity: _quantity,
                    unitCode: '',
                    unitName: '-',
                    price: _price,
                    discount: 0,
                    total: _total,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                } else if (item.items[i].Amount < 0) {
                  _totalItemDiscount = _totalItemDiscount + Math.abs(item.items[i].Amount);
  
                  _zeroVatPriceDiscountTotal = _zeroVatPriceDiscountTotal + Math.abs(item.items[i].Amount);
                }
              }
            }
          } else {
            _percentVat = nonVat;
            _percentVatText = nonVatText;
  
            if (item.items[i].Amount >= 0) {
              _totalBeforeDiscount = _totalBeforeDiscount + item.items[i].Amount;
              _nonVatPriceTotalBeforeDiscount = _nonVatPriceTotalBeforeDiscount + item.items[i].Amount;
  
              let _description = item.items[i].Description

              if (item.items[i].SalesItemLineDetail?.ItemRef?.name) {
                _description = item.items[i].SalesItemLineDetail?.ItemRef?.name + " : " + item.items[i].Description
              }

              _items.push({
                number: i + 1 + '',
                // description: item.items[i].SalesItemLineDetail.ItemRef.name,
                description: _description,
                quantity: _quantity,
                unitCode: '',
                unitName: '-',
                price: _price,
                discount: 0,
                total: _total,
                percentVat: _percentVat,
                percentVatText: _percentVatText,
              });
            } else if (item.items[i].Amount < 0) {
              _totalItemDiscount = _totalItemDiscount + Math.abs(item.items[i].Amount);
  
              _nonVatPriceDiscountTotal = _nonVatPriceDiscountTotal + Math.abs(item.items[i].Amount);
            }
          }
        } else {
          if (item.items[i].DetailType === 'DiscountLineDetail') {
            _discount = _discount + item.items[i].Amount;
          }
        }
      }
    }

    _vatPriceTotal = Number(
      toFixedTrunc(
        _vatPriceTotalBeforeDiscount -
          (Math.round((_vatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
          _vatPriceDiscountTotal,
        2
      )
    );

    _zeroVatPriceTotal = Number(
      (
        _zeroVatPriceTotalBeforeDiscount -
        (Math.round((_zeroVatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
        _zeroVatPriceDiscountTotal
      ).toFixed(2)
    );

    _nonVatPriceTotal = Number(
      (
        _nonVatPriceTotalBeforeDiscount -
        (Math.round((_nonVatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
        _nonVatPriceDiscountTotal
      ).toFixed(2)
    );

    _discount = _discount + _totalItemDiscount;
    
    let _reference = ''
    if (item.customField) {
      const _receiptNo = item.customField.find(_customObj => _customObj.Name === 'Receipt No')
      const _paymentDate = item.customField.find(_customObj => _customObj.Name === 'Payment Date')
      const _tinNo = item.customField.find(_customObj => _customObj.Name === 'TIN No' || _customObj.Name === 'taxId')
      const _branchObject = item.customField.find(_customObj => _customObj.Name === 'branchNumber')

      if (_receiptNo?.StringValue ) {
        _reference = _receiptNo.StringValue || ''
      }

      if (_paymentDate?.StringValue) {
        let _d = _paymentDate.StringValue.split('/');

        _date = new Date(_d[2] + '/' + _d[1] + '/' + _d[0]);

        _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
        _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();
      }

      if (_tinNo?.StringValue) {
        _customer.taxId = _tinNo.StringValue.trim()

        if (_customer.taxId.length !== 13 || isNaN(_customer.taxId)) {
          _customer.taxNumberType = 'CCPT'
        } else {
          if (_branchObject?.StringValue) {
            _customer.taxNumberType = 'TXID'
            
            if (isNaN(_branchObject?.StringValue)) {
              _customer.branchNumber = '00000'
              _customer.branchText = 'Head Office'
            } else {
              const _branchNumber = String(_branchObject?.StringValue).padStart(5, "0")
              if (_branchNumber !== "00000") {
                _customer.branchNumber = _branchNumber
                _customer.branchText = `Branch ${_branchNumber}`
              } else {
                _customer.branchNumber = '00000'
                _customer.branchText = 'Head Office'
              }
            }
          } else if (hasSpecificValue(_customer, 'head office')) {
            _customer.taxNumberType = 'TXID'
            _customer.branchNumber = '00000'
            _customer.branchText = 'Head Office'
          } else if (hasSpecificValue(_customer, 'สำนักงานใหญ่')) {
            _customer.taxNumberType = 'TXID'
            _customer.branchNumber = '00000'
            _customer.branchText = 'สำนักงานใหญ่'
          } else if (hasSpecificValue(_customer, 'สาขา')) {
            _customer.taxNumberType = 'TXID'
            
            const _value = getValue(_customer, 'สาขา')
            const matchBranch = findValueNextToBranch(_value)
            if (matchBranch) {
              const _branchNumber = String(matchBranch).padStart(5, "0")

              _customer.branchNumber = _branchNumber
              _customer.branchText = `สาขา ${_branchNumber}`
            } else {
              _customer.branchNumber = '00000'
              _customer.branchText = 'สำนักงานใหญ่'
            }
          } else if (hasSpecificValue(_customer, 'branch')) {
            _customer.taxNumberType = 'TXID'
            
            const _value = getValue(_customer, 'branch')
            const matchBranch = findValueNextToBranch(_value)
            if (matchBranch) {
              const _branchNumber = String(matchBranch).padStart(5, "0")

              _customer.branchNumber = _branchNumber
              _customer.branchText = `Branch ${_branchNumber}`
            } else {
              _customer.branchNumber = '00000'
              _customer.branchText = 'Head Office'
            }
          } else {
            _customer.taxNumberType = 'NIDN'
          }
        }
      } else {
        const _taxIdNumber = find13DigitNumber(_customer.addressLineOne)
        if (_taxIdNumber) {
          _customer.taxId = _taxIdNumber

          if (_customer.taxId.length !== 13 || isNaN(_customer.taxId)) {
            _customer.taxNumberType = 'CCPT'
          } else {
            if (_branchObject?.StringValue) {
              _customer.taxNumberType = 'TXID'
              
              if (isNaN(_branchObject?.StringValue)) {
                _customer.branchNumber = '00000'
                _customer.branchText = 'Head Office'
              } else {
                const _branchNumber = String(_branchObject?.StringValue).padStart(5, "0")
                if (_branchNumber !== "00000") {
                  _customer.branchNumber = _branchNumber
                  _customer.branchText = `Branch ${_branchNumber}`
                } else {
                  _customer.branchNumber = '00000'
                  _customer.branchText = 'Head Office'
                }
              }
            } else if (hasSpecificValue(_customer, 'head office')) {
              _customer.taxNumberType = 'TXID'
              _customer.branchNumber = '00000'
              _customer.branchText = 'Head Office'
            } else if (hasSpecificValue(_customer, 'สำนักงานใหญ่')) {
              _customer.taxNumberType = 'TXID'
              _customer.branchNumber = '00000'
              _customer.branchText = 'สำนักงานใหญ่'
            } else if (hasSpecificValue(_customer, 'สาขา')) {
              _customer.taxNumberType = 'TXID'
              
              const _value = getValue(_customer, 'สาขา')
              const matchBranch = findValueNextToBranch(_value)
              if (matchBranch) {
                const _branchNumber = String(matchBranch).padStart(5, "0")

                _customer.branchNumber = _branchNumber
                _customer.branchText = `สาขา ${_branchNumber}`
              } else {
                _customer.branchNumber = '00000'
                _customer.branchText = 'สำนักงานใหญ่'
              }
            } else if (hasSpecificValue(_customer, 'branch')) {
              _customer.taxNumberType = 'TXID'
              
              const _value = getValue(_customer, 'branch')
              const matchBranch = findValueNextToBranch(_value)
              if (matchBranch) {
                const _branchNumber = String(matchBranch).padStart(5, "0")

                _customer.branchNumber = _branchNumber
                _customer.branchText = `Branch ${_branchNumber}`
              } else {
                _customer.branchNumber = '00000'
                _customer.branchText = 'Head Office'
              }
            } else {
              _customer.taxNumberType = 'NIDN'
            }
  
          }
        }
      }
    }

    let _note = ''
    if (item.note.length <= 500) {
      _note = item.note
    }

    // console.log({
    //   pathname: documentPath,
    //   state: {
    //     company: {
    //       ...company,
    //       includeVat: item.includeVat,
    //     },
    //     customer: _customer,
    //     date: _date.toISOString(),
    //     dateBE: _dateBE,
    //     dateCE: _dateCE,
    //     discount: _discount * 1,
    //     grandTotal: item.grandTotal * 1,
    //     amountTotal: item.amountTotal * 1,
    //     nonVatPriceTotal: _nonVatPriceTotal,
    //     zeroVatPriceTotal: _zeroVatPriceTotal,
    //     vatPriceTotal: _vatPriceTotal,
    //     vatTotal: item.vatTotal * 1,
    //     items: _items,
    //     reference: _reference,
    //     note: _note,
    //     number: item.number,
    //     percentVat: percentVat,
    //     receivedBy: '',
    //     reIssue: false,
    //     backUrl: '/etax/sources/quickbooks',
    //     includeVat: item.includeVat,
    //   },
    // })

    history.push({
      pathname: documentPath,
      state: {
        company: {
          ...company,
          includeVat: item.includeVat,
        },
        customer: _customer,
        date: _date.toISOString(),
        dateBE: _dateBE,
        dateCE: _dateCE,
        dueDate: _dueDate,
        dueDateBE: _dueDateBE,
        dueDateCE: _dueDateCE,
        discount: _discount * 1,
        grandTotal: item.grandTotal * 1,
        grandTotalTH: numberToTextTh(item.grandTotal * 1),
        grandTotalEN: numberToTextEn(item.grandTotal * 1),
        amountTotal: item.amountTotal * 1,
        nonVatPriceTotal: _nonVatPriceTotal,
        zeroVatPriceTotal: _zeroVatPriceTotal,
        vatPriceTotal: _vatPriceTotal,
        vatTotal: item.vatTotal * 1,
        items: _items,
        reference: _reference,
        note: _note,
        number: item.number,
        percentVat: percentVat,
        receivedBy: '',
        reIssue: false,
        backUrl: '/etax/sources/quickbooks',
        includeVat: item.includeVat,
        extraAttributes: item.extraAttributes
        
      },
    });
  };

  useEffect(() => {
    console.log('userEffect initial company call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);

                  if (_company.registerVat) {
                    setRegisterVat(true);
                  } else {
                    setRegisterVat(false);
                  }
                } else {
                  setCompany(response.data[0]);

                  if (response.data[0].registerVat) {
                    setRegisterVat(true);
                  } else {
                    setRegisterVat(false);
                  }
                }
                
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          if (search) {
            console.log('search Running!!!');

            axios
              .get(API_URL + '/sources/quickbooks/invoices', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
                params: {
                  docNumber: search,
                },
              })
              .then(async (response) => {
                console.log('response', response)

                if (response.data.Invoice.length) {
                  const _number = response.data.Invoice[0].DocNumber;

                  await axios
                    .get(API_URL + `/documents?number=${_number}`, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId,
                        'Content-Type': 'application/json',
                      },
                    })
                    .then((responseAllDocs) => {
                      console.log('getNewDocuments', response);
                      let invoice_list = response.data.Invoice || [];

                      // let _pageDropdown = [];

                      // for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                      //   _pageDropdown.push({
                      //     key: i,
                      //     text: t('quickBook.page') + ' ' + i,
                      //     iconProps: { iconName: 'Page' },
                      //     onClick: () => {
                      //       console.log('Selected page: ' + i);
                      //       setCurrentPage(i);
                      //     },
                      //   });
                      // }

                      // setPageDropdown(_pageDropdown);

                      // if (currentPage > Math.ceil(total_items / pageSize)) {
                      //   setCurrentPage(1);
                      // }

                      setPageData(paginate(pageSize * 100, 1, pageSize, 10));
                      // setDocuments(invoice_list);

                      const _docs = [];

                      if (invoice_list.length > 0) {
                        invoice_list.forEach((invoice) => {
                          let _documentDate = invoice.TxnDate.split('-').reverse().join('-');
                          let _dueDate = invoice.DueDate?.split('-').reverse().join('-') || ""

                          let _createdTime = toThaiDateString(invoice.MetaData.CreateTime);
                          let _createdTimestamp = invoice.MetaData.CreateTime;

                          let _items = invoice.Line;

                          let _includeVat = false;
                          if (invoice.GlobalTaxCalculation === 'TaxInclusive') {
                            _includeVat = true;
                          }

                          let _amountTotal = Math.round((invoice.TotalAmt - invoice.TxnTaxDetail.TotalTax) * 100) / 100;
                          let _vatTotal = invoice.TxnTaxDetail.TotalTax;
                          let _grandTotal = invoice.TotalAmt;

                          let _customerId = invoice.CustomerRef.value
                          let _customerName = invoice.CustomerRef.name;

                          let _address = '';
                          let _postcode = '';
                          let _email = '';

                          if (invoice?.BillAddr?.Line1) {
                            if (invoice?.BillAddr?.Line1) {
                              _address = _address + invoice.BillAddr.Line1;
                            }

                            if (invoice?.BillAddr?.Line2) {
                              _address = _address + ' ' + invoice.BillAddr.Line2;
                            }

                            if (invoice?.BillAddr?.Line3) {
                              _address = _address + ' ' + invoice.BillAddr.Line3;
                            }

                            if (invoice?.BillAddr?.Line4) {
                              _address = _address + ' ' + invoice.BillAddr.Line4;
                            }

                            if (invoice?.BillAddr?.City) {
                              _address = _address + ' ' + invoice.BillAddr.City;
                            }

                            if (invoice?.BillAddr?.PostalCode) {
                              _postcode = invoice.BillAddr.PostalCode;
                            }

                            let findPostcode = _address.match(/\b[1-9]\d{4}\b/);

                            if (findPostcode && findPostcode.length > 0) {
                              findPostcode.forEach((number) => {
                                if (number.length === 5 && invoice.BillAddr.PostalCode == null) {
                                  _postcode = number;
                                  _address = _address.replace(number, '');
                                }
                              });
                            }
                          } else {
                            _address = invoice.CustomerRef?.name || "-";
                            _postcode = '00000';
                          }

                          if (invoice?.BillEmail?.Address) {
                            _email = invoice.BillEmail.Address;
                          }

                          const _extraAttributes = {
                            attribute001: "QuickBooks"
                          }

                          if (isCompanyName(invoice?.BillAddr?.Line1)) {
                            _extraAttributes.attribute002 = invoice?.BillAddr?.Line1 || ""
                            _extraAttributes.attribute003 = invoice?.BillAddr?.Line2 || ""
                            _extraAttributes.attribute004 = invoice?.BillAddr?.Line3 || ""
                            _extraAttributes.attribute005 = invoice?.BillAddr?.Line4 || ""
                            _extraAttributes.attribute006 = invoice?.BillAddr?.City || ""
                            _extraAttributes.attribute007 = invoice?.BillAddr?.PostalCode || ""
                            
                          } else {
                            _extraAttributes.attribute002 = invoice?.CustomerRef.name || ""
                            _extraAttributes.attribute003 = invoice?.BillAddr?.Line1 || ""
                            _extraAttributes.attribute004 = invoice?.BillAddr?.Line2 || ""
                            _extraAttributes.attribute005 = invoice?.BillAddr?.Line3 || ""
                            _extraAttributes.attribute006 = invoice?.BillAddr?.Line4 || ""
                            _extraAttributes.attribute007 = invoice?.BillAddr?.City || ""
                            _extraAttributes.attribute008 = invoice?.BillAddr?.PostalCode || ""
                          }
          
                          let _customField = null
                          if (invoice.CustomField) {
                            _customField = invoice.CustomField
          
                            const length = Object.keys(_extraAttributes).length
                            
                            invoice.CustomField.forEach((obj, index) => {
                              const prefix = "attribute"
                              const suffix = index + length + 1
                            
                              const key = prefix + String(suffix).padStart(3, "0")
                              _extraAttributes[key] = obj.StringValue || "";
                            });
                          }

                          _docs.push({
                            key: invoice.Id,
                            documentDate: _documentDate,
                            dueDate: _dueDate,
                            createdTime: _createdTime,
                            createdTimestamp: _createdTimestamp,
                            number: invoice.DocNumber,
                            customerId: _customerId,
                            customerName: _customerName,
                            customerAddress: _address,
                            customerEmail: _email,
                            items: _items,
                            amountTotal: _amountTotal,
                            vatTotal: _vatTotal,
                            grandTotal: _grandTotal,
                            customerPostcode: _postcode,
                            discount: 0,
                            note: invoice?.CustomerMemo?.value || '',
                            includeVat: _includeVat,
                            etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice.DocNumber),
                            customField: _customField,
                            extraAttributes: _extraAttributes
                          });
                        });
                      }
                      setItems(_docs);

                      console.log('_docs', _docs)

                      setLoadDataComplete(true);
                      setGetNewDocuments(false);
                      setIsCreateDocumentComplete(false);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
                setItems([]);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
              });
          } else {
            console.log('getNewOrder Running!!!');

            const _sortBy = getSortByAndSortDirection(primarySort)

            const [response] = await Promise.all([
              axios
                .get(API_URL + '/sources/quickbooks/invoices', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                  },
                  params: {
                    from: from,
                    to: to,
                    page: currentPage,
                    pageSize: pageSize,
                    sortBy: _sortBy,
                    sortDirection: sortDirection
                  },
                })
                .catch((error) => {
                  console.log(error);

                  setItems([]);

                  setLoadDataComplete(true);
                  setGetNewDocuments(false);
                  setIsCreateDocumentComplete(false);
                })
            ]);

            console.log("response", response)

            if (response) {
              let invoice_list = response.data?.Invoice || [];
              // let total_items = response.data?.totalCount || 0;
  
              // console.log('getNewDocuments', invoice_list);
  
              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  invoice_list.sort(function (a, b) {
                    var aa = a.TxnDate.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.TxnDate.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    var aa = a.TxnDate.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.TxnDate.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  invoice_list.sort((a, b) => {
                    return b.DocNumber - a.DocNumber;
                  });
                } else {
                  invoice_list.sort((a, b) => {
                    return a.DocNumber - b.DocNumber;
                  });
                }
              }
  
              // let _pageDropdown = [];
  
              // for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
              //   _pageDropdown.push({
              //     key: i,
              //     text: t('quickBook.page') + ' ' + i,
              //     iconProps: { iconName: 'Page' },
              //     onClick: () => {
              //       console.log('Selected page: ' + i);
              //       setCurrentPage(i);
              //     },
              //   });
              // }
  
              // setPageDropdown(_pageDropdown);
  
              // if (currentPage > Math.ceil(total_items / pageSize)) {
              //   setCurrentPage(1);
              // }
  
              setPageData(paginate(pageSize * 100, 1, pageSize, 10));
              // setDocuments(invoice_list);

              const _numberList = invoice_list.map(_invoice => { 
                return _invoice.DocNumber
              }).join(",")

              let _etaxDocuments = []
              if (_numberList) {
                const documentsResponse = await axios.get(`${API_URL}/documents/?numberList=${_numberList}`, {
                                            headers: {
                                                Authorization: "Bearer " + tokenResponse.accessToken,
                                                "cid": companyId
                                            },
                                          })
                                          .catch((error) => console.log(error))
                
                _etaxDocuments = documentsResponse.data
              }

              const _docs = [];
  
              for (let i = 0; i < invoice_list.length; i++) {
                let _documentDate = invoice_list[i].TxnDate.split('-').reverse().join('-');
                let _dueDate = invoice_list[i].DueDate?.split('-').reverse().join('-') || ""

                let _createdTime = toThaiDateString(invoice_list[i].MetaData.CreateTime);
                let _createdTimestamp = invoice_list[i].MetaData.CreateTime;
  
                let _items = invoice_list[i].Line;
  
                let _includeVat = false;
                if (invoice_list[i].GlobalTaxCalculation === 'TaxInclusive') {
                  _includeVat = true;
                }

                // console.log('invoice_list[i]', invoice_list[i]?.BillAddr)
  
                let _amountTotal = Math.round((invoice_list[i].TotalAmt - invoice_list[i].TxnTaxDetail.TotalTax) * 100) / 100;
                let _vatTotal = invoice_list[i].TxnTaxDetail.TotalTax;
                let _grandTotal = invoice_list[i].TotalAmt;

                let _customerId = invoice_list[i].CustomerRef.value
                let _customerName = invoice_list[i].CustomerRef.name;
                let _address = '';
                let _postcode = '';
                let _email = '';
  
                if (invoice_list[i]?.BillAddr?.Line1) {
                  if (invoice_list[i]?.BillAddr?.Line1) {
                    _address = _address + invoice_list[i].BillAddr.Line1;
                  }
  
                  if (invoice_list[i]?.BillAddr?.Line2) {
                    _address = _address + ' ' + invoice_list[i].BillAddr.Line2;
                  }
  
                  if (invoice_list[i]?.BillAddr?.Line3) {
                    _address = _address + ' ' + invoice_list[i].BillAddr.Line3;
                  }
  
                  if (invoice_list[i]?.BillAddr?.Line4) {
                    _address = _address + ' ' + invoice_list[i].BillAddr.Line4;
                  }
  
                  if (invoice_list[i]?.BillAddr?.City) {
                    _address = _address + ' ' + invoice_list[i].BillAddr.City;
                  }
  
                  if (invoice_list[i]?.BillAddr?.PostalCode) {
                    _postcode = invoice_list[i].BillAddr.PostalCode;
                  }
  
                  let findPostcode = _address.match(/\b[1-9]\d{4}\b/);
  
                  if (findPostcode && findPostcode.length > 0) {

                    findPostcode.forEach((number) => {
                      if (number.length === 5 && invoice_list[i]?.BillAddr?.PostalCode == null) {
                        _postcode = number;
                        _address = _address.replace(number, '');
                      }
                    });
                  }
                } else {
                  _address = invoice_list[i].CustomerRef?.name || "-";
                  _postcode = '00000';
                }
  
                if (invoice_list[i]?.BillEmail?.Address) {
                  _email = invoice_list[i].BillEmail.Address;
                }

                const _extraAttributes = {
                  attribute001: "QuickBooks"
                }

                if (isCompanyName(invoice_list[i]?.BillAddr?.Line1)) {
                  _extraAttributes.attribute002 = invoice_list[i]?.BillAddr?.Line1 || ""
                  _extraAttributes.attribute003 = invoice_list[i]?.BillAddr?.Line2 || ""
                  _extraAttributes.attribute004 = invoice_list[i]?.BillAddr?.Line3 || ""
                  _extraAttributes.attribute005 = invoice_list[i]?.BillAddr?.Line4 || ""
                  _extraAttributes.attribute006 = invoice_list[i]?.BillAddr?.City || ""
                  _extraAttributes.attribute007 = invoice_list[i]?.BillAddr?.PostalCode || ""
                  
                } else {
                  _extraAttributes.attribute002 = invoice_list[i]?.CustomerRef.name || ""
                  _extraAttributes.attribute003 = invoice_list[i]?.BillAddr?.Line1 || ""
                  _extraAttributes.attribute004 = invoice_list[i]?.BillAddr?.Line2 || ""
                  _extraAttributes.attribute005 = invoice_list[i]?.BillAddr?.Line3 || ""
                  _extraAttributes.attribute006 = invoice_list[i]?.BillAddr?.Line4 || ""
                  _extraAttributes.attribute007 = invoice_list[i]?.BillAddr?.City || ""
                  _extraAttributes.attribute008 = invoice_list[i]?.BillAddr?.PostalCode || ""
                }

                let _customField = null
                if (invoice_list[i]?.CustomField) {
                  _customField = invoice_list[i]?.CustomField

                  const length = Object.keys(_extraAttributes).length
                  
                  invoice_list[i]?.CustomField.forEach((obj, index) => {
                    const prefix = "attribute"
                    const suffix = index + length + 1
                  
                    const key = prefix + String(suffix).padStart(3, "0")
                    _extraAttributes[key] = obj.StringValue || "";
                  });
                }
                
                _docs.push({
                  key: invoice_list[i].Id,
                  documentDate: _documentDate,
                  dueDate: _dueDate,
                  createdTime: _createdTime,
                  createdTimestamp: _createdTimestamp,
                  number: invoice_list[i].DocNumber,
                  customerId: _customerId,
                  customerName: _customerName,
                  customerAddress: _address,
                  customerEmail: _email,
                  items: _items,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  grandTotal: _grandTotal,
                  customerPostcode: _postcode,
                  discount: 0,
                  note: invoice_list[i]?.CustomerMemo?.value || '',
                  includeVat: _includeVat,
                  etaxCreated: _etaxDocuments.some(_doc => _doc.data.number === invoice_list[i].DocNumber),
                  customField: _customField,
                  extraAttributes: _extraAttributes
                });
              }
  
              setItems(_docs);

              console.log('_docs', _docs)
  
              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            } else {
              setItems([]);
  
              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            }
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);

          setItems([]);
  
          setLoadDataComplete(true);
          setGetNewDocuments(false);
          setIsCreateDocumentComplete(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    setLoadDataComplete(false);
    setItems([]);
    setGetNewDocuments(true);

    setSearch("");

    sessionStorage.setItem('quickbook-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column3',
      name: t('quickBook.documentDate'),
      fieldName: 'documentDate',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('quickbook-primarySort', 'documentDate');
        sessionStorage.setItem('quickbook-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('quickbook-sort-createdTime');
        sessionStorage.removeItem('quickbook-sort-number');
        sessionStorage.removeItem('quickbook-sort-customerName');
        sessionStorage.removeItem('quickbook-sort-vatTotal');
        sessionStorage.removeItem('quickbook-sort-grandTotal');

        if (!isSortedDescendingDocumentDate) {
          setSortDirection('DESC');
          sessionStorage.setItem('quickbook-sortDirection', 'DESC');
        } else {
          setSortDirection('ASC');
          sessionStorage.setItem('quickbook-sortDirection', 'ASC');
        }

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate.replaceAll("-", "/")}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('quickBook.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('quickbook-primarySort', 'number');
        sessionStorage.setItem('quickbook-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('quickbook-sort-createdTime');
        sessionStorage.removeItem('quickbook-sort-documentDate');
        sessionStorage.removeItem('quickbook-sort-customerName');
        sessionStorage.removeItem('quickbook-sort-vatTotal');
        sessionStorage.removeItem('quickbook-sort-grandTotal');

        if (!isSortedDescendingNumber) {
          setSortDirection('DESC');
          sessionStorage.setItem('quickbook-sortDirection', 'DESC');
        } else {
          setSortDirection('ASC');
          sessionStorage.setItem('quickbook-sortDirection', 'ASC');
        }

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('quickBook.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column5',
      name: t('quickBook.customerAddress'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 270,
      isRowHeader: true,
      isResizable: true,
      onColumnClick: '',
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('quickBook.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('quickBook.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('quickBook.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('quickBook.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('quickBook.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('quickBook.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('quickBook.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('quickBook.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('quickBook.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('quickBook.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('quickBook.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('quickBook.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('quickBook.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'quickbooks',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/quickbooks/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let taxCodeList = [];
    let taxRateList = [];

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/quickbooks/tax-codes', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            taxCodeList = response.data.TaxCode;
            console.log(taxCodeList);
          });

        await axios
          .get(API_URL + '/sources/quickbooks/tax-rates', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            taxRateList = response.data.TaxRate;
            console.log(taxRateList);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        // instance.acquireTokenRedirect(silentRequest);
      });

    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                console.log('item key: ', _selectionItems[j].key);
                _selectionItems[j].status = 'processing';

                console.log('items: ', _selectionItems);
                setItems([..._selectionItems]);

                console.log('item: ', _selectionItems[j]);

                let d = selectedItems[i].documentDate.split('-');

                let _date = new Date(d[2] + '/' + d[1] + '/' + d[0]);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _dueDate = ""
                let _dueDateBE = ""
                let _dueDateCE = ""

                let dueD = selectedItems[i].dueDate ? selectedItems[i].dueDate.split('-') : ""
                if (dueD) {
                  _dueDate = new Date(dueD[2] + '/' + dueD[1] + '/' + dueD[0]);
                  _dueDateBE = `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);
                  _dueDateCE = `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();
                }

                let _taxId = 'N/A';
                let _taxNumberType = 'OTHR';
                let _branchNumber = '';
                let _branchText = '';

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: '',
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode ? selectedItems[i].customerPostcode : '00000',
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];

                let _totalBeforeDiscount = 0;
                let _totalItemDiscount = 0;
                let _discount = 0;

                let _vatPriceTotal = 0;
                let _vatPriceTotalBeforeDiscount = 0;
                let _vatPriceDiscountTotal = 0;

                let _zeroVatPriceTotal = 0;
                let _zeroVatPriceTotalBeforeDiscount = 0;
                let _zeroVatPriceDiscountTotal = 0;

                let _nonVatPriceTotal = 0;
                let _nonVatPriceTotalBeforeDiscount = 0;
                let _nonVatPriceDiscountTotal = 0;

                for (let k = 0; k < selectedItems[i].items.length; k++) {
                  if (selectedItems[i].items[k].Amount) {
                    if (selectedItems[i].items[k].Id) {
                      let _quantity = selectedItems[i].items[k].SalesItemLineDetail.Qty;
                      let _price = Math.round(selectedItems[i].items[k].SalesItemLineDetail.UnitPrice * 100) / 100;
  
                      let _total = Math.round(_price * _quantity * 100) / 100;
  
                      let _taxCode = selectedItems[i].items[k].SalesItemLineDetail.TaxCodeRef?.value;
  
                      let _percentVat;
                      let _percentVatText;
  
                      if (_taxCode) {
                        let taxCodeObj = taxCodeList.find((_taxCodeObj) => _taxCodeObj.Id === _taxCode);
  
                        const taxRateDetailLength = taxCodeObj.SalesTaxRateList.TaxRateDetail.length;
                        let taxRateObj = taxRateList.find(
                          (_taxRateObj) => _taxRateObj.Id === taxCodeObj.SalesTaxRateList.TaxRateDetail[taxRateDetailLength - 1].TaxRateRef.value
                        );
  
                        const EffectiveTaxRateList = taxRateObj.EffectiveTaxRate;
  
                        const taxRate = EffectiveTaxRateList[EffectiveTaxRateList.length - 1].RateValue;
  
                        if (taxRate > 0) {
                          _percentVat = percentVat;
                          _percentVatText = percentVatText;
  
                          if (selectedItems[i].items[k].Amount >= 0) {
                            _totalBeforeDiscount = _totalBeforeDiscount + selectedItems[i].items[k].Amount;
                            _vatPriceTotalBeforeDiscount = _vatPriceTotalBeforeDiscount + selectedItems[i].items[k].Amount;
  
                            let _description = selectedItems[i].items[k].Description

                            if (selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name) {
                              _description = selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name + (selectedItems[i].items[k].Description ? (" : " + selectedItems[i].items[k].Description) : "")
                            }

                            _items.push({
                              number: i + 1 + '',
                              // description: selectedItems[i].items[k].SalesItemLineDetail.ItemRef.name,
                              description: _description,
                              quantity: _quantity,
                              unitCode: '',
                              unitName: '-',
                              price: _price,
                              discount: 0,
                              total: _total,
                              percentVat: _percentVat,
                              percentVatText: _percentVatText,
                            });
                          } else if (selectedItems[i].items[k].Amount < 0) {
                            _totalItemDiscount = _totalItemDiscount + Math.abs(selectedItems[i].items[k].Amount);
  
                            _vatPriceDiscountTotal = _vatPriceDiscountTotal + Math.abs(selectedItems[i].items[k].Amount);
                          }
                        } else {
                          if (taxCodeObj.Name.includes('Exempt') || taxCodeObj.Name.includes('No VAT')) {
                            _percentVat = nonVat;
                            _percentVatText = nonVatText;
  
                            if (selectedItems[i].items[k].Amount >= 0) {
                              _totalBeforeDiscount = _totalBeforeDiscount + selectedItems[i].items[k].Amount;
                              _nonVatPriceTotalBeforeDiscount = _nonVatPriceTotalBeforeDiscount + selectedItems[i].items[k].Amount;
  
                              let _description = selectedItems[i].items[k].Description

                              if (selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name) {
                                _description = selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name + " : " + selectedItems[i].items[k].Description
                              }

                              _items.push({
                                number: i + 1 + '',
                                // description: selectedItems[i].items[k].SalesItemLineDetail.ItemRef.name,
                                description: _description,
                                quantity: _quantity,
                                unitCode: '',
                                unitName: '-',
                                price: _price,
                                discount: 0,
                                total: _total,
                                percentVat: _percentVat,
                                percentVatText: _percentVatText,
                              });
                            } else if (selectedItems[i].items[k].Amount < 0) {
                              _totalItemDiscount = _totalItemDiscount + Math.abs(selectedItems[i].items[k].Amount);
  
                              _nonVatPriceDiscountTotal = _nonVatPriceDiscountTotal + Math.abs(selectedItems[i].items[k].Amount);
                            }
                          } else {
                            _percentVat = zeroVat;
                            _percentVatText = zeroVatText;
  
                            if (selectedItems[i].items[k].Amount >= 0) {
                              _totalBeforeDiscount = _totalBeforeDiscount + selectedItems[i].items[k].Amount;
                              _zeroVatPriceTotalBeforeDiscount = _zeroVatPriceTotalBeforeDiscount + selectedItems[i].items[k].Amount;
  
                              let _description = selectedItems[i].items[k].Description

                              if (selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name) {
                                _description = selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name + " : " + selectedItems[i].items[k].Description
                              }

                              _items.push({
                                number: i + 1 + '',
                                // description: selectedItems[i].items[k].SalesItemLineDetail.ItemRef.name,
                                description: _description,
                                quantity: _quantity,
                                unitCode: '',
                                unitName: '-',
                                price: _price,
                                discount: 0,
                                total: _total,
                                percentVat: _percentVat,
                                percentVatText: _percentVatText,
                              });
                            } else if (selectedItems[i].items[k].Amount < 0) {
                              _totalItemDiscount = _totalItemDiscount + Math.abs(selectedItems[i].items[k].Amount);
  
                              _zeroVatPriceDiscountTotal = _zeroVatPriceDiscountTotal + Math.abs(selectedItems[i].items[k].Amount);
                            }
                          }
                        }
                      } else {
                        _percentVat = nonVat;
                        _percentVatText = nonVatText;
  
                        if (selectedItems[i].items[k].Amount >= 0) {
                          _totalBeforeDiscount = _totalBeforeDiscount + selectedItems[i].items[k].Amount;
                          _nonVatPriceTotalBeforeDiscount = _nonVatPriceTotalBeforeDiscount + selectedItems[i].items[k].Amount;
  
                          let _description = selectedItems[i].items[k].Description

                          if (selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name) {
                            _description = selectedItems[i].items[k].SalesItemLineDetail?.ItemRef?.name + " : " + selectedItems[i].items[k].Description
                          }

                          _items.push({
                            number: i + 1 + '',
                            // description: selectedItems[i].items[k].SalesItemLineDetail.ItemRef.name,
                            description: _description,
                            quantity: _quantity,
                            unitCode: '',
                            unitName: '-',
                            price: _price,
                            discount: 0,
                            total: _total,
                            percentVat: _percentVat,
                            percentVatText: _percentVatText,
                          });
                        } else if (selectedItems[i].items[k].Amount < 0) {
                          _totalItemDiscount = _totalItemDiscount + Math.abs(selectedItems[i].items[k].Amount);
  
                          _nonVatPriceDiscountTotal = _nonVatPriceDiscountTotal + Math.abs(selectedItems[i].items[k].Amount);
                        }
                      }
                    } else {
                      if (selectedItems[i].items[k].DetailType === 'DiscountLineDetail') {
                        _discount = _discount + selectedItems[i].items[k].Amount;
                      }
                    }
                  }
                }

                _vatPriceTotal = Number(
                  toFixedTrunc(
                    _vatPriceTotalBeforeDiscount -
                      (Math.round((_vatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
                      _vatPriceDiscountTotal,
                    2
                  )
                );

                _zeroVatPriceTotal = Number(
                  (
                    _zeroVatPriceTotalBeforeDiscount -
                    (Math.round((_zeroVatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
                    _zeroVatPriceDiscountTotal
                  ).toFixed(2)
                );

                _nonVatPriceTotal = Number(
                  (
                    _nonVatPriceTotalBeforeDiscount -
                    (Math.round((_nonVatPriceTotalBeforeDiscount / _totalBeforeDiscount) * 10000) / 10000) * _discount -
                    _nonVatPriceDiscountTotal
                  ).toFixed(2)
                );

                _discount = _discount + _totalItemDiscount;

                let _reference = ''
                if (selectedItems[i].customField) {
                  const _receiptNo = selectedItems[i].customField.find(_customObj => _customObj.Name === 'Receipt No')
                  const _paymentDate = selectedItems[i].customField.find(_customObj => _customObj.Name === 'Payment Date')
                  const _tinNo = selectedItems[i].customField.find(_customObj => _customObj.Name === 'TIN No' || _customObj.Name === 'taxId')
                  const _branchObject = selectedItems[i].customField.find(_customObj => _customObj.Name === 'branchNumber')

                  if (_receiptNo?.StringValue ) {
                    _reference = _receiptNo.StringValue || ''
                  }

                  if (_paymentDate?.StringValue) {
                    let _d = _paymentDate.StringValue.split('/');

                    _date = new Date(_d[2] + '/' + _d[1] + '/' + _d[0]);

                    _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                    _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();
                  }

                  if (_tinNo?.StringValue) {
                    _customer.taxId = _tinNo.StringValue.trim()

                    if (_customer.taxId.length !== 13 || isNaN(_customer.taxId)) {
                      _customer.taxNumberType = 'CCPT'
                    } else {
                      if (_branchObject?.StringValue) {
                        _customer.taxNumberType = 'TXID'
                        
                        if (isNaN(_branchObject?.StringValue)) {
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'Head Office'
                        } else {
                          const _branchNumber = String(_branchObject?.StringValue).padStart(5, "0")
                          if (_branchNumber !== "00000") {
                            _customer.branchNumber = _branchNumber
                            _customer.branchText = `Branch ${_branchNumber}`
                          } else {
                            _customer.branchNumber = '00000'
                            _customer.branchText = 'Head Office'
                          }
                        }
                      } else if (hasSpecificValue(_customer, 'head office')) {
                        _customer.taxNumberType = 'TXID'
                        _customer.branchNumber = '00000'
                        _customer.branchText = 'Head Office'
                      } else if (hasSpecificValue(_customer, 'สำนักงานใหญ่')) {
                        _customer.taxNumberType = 'TXID'
                        _customer.branchNumber = '00000'
                        _customer.branchText = 'สำนักงานใหญ่'
                      } else if (hasSpecificValue(_customer, 'สาขา')) {
                        _customer.taxNumberType = 'TXID'
                        
                        const _value = getValue(_customer, 'สาขา')
                        const matchBranch = findValueNextToBranch(_value)
                        if (matchBranch) {
                          const _branchNumber = String(matchBranch).padStart(5, "0")

                          _customer.branchNumber = _branchNumber
                          _customer.branchText = `สาขา ${_branchNumber}`
                        } else {
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'สำนักงานใหญ่'
                        }
                      } else if (hasSpecificValue(_customer, 'branch')) {
                        _customer.taxNumberType = 'TXID'
                        
                        const _value = getValue(_customer, 'branch')
                        const matchBranch = findValueNextToBranch(_value)
                        if (matchBranch) {
                          const _branchNumber = String(matchBranch).padStart(5, "0")

                          _customer.branchNumber = _branchNumber
                          _customer.branchText = `Branch ${_branchNumber}`
                        } else {
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'Head Office'
                        }
                      } else {
                        _customer.taxNumberType = 'NIDN'
                      }
            
                    }
                  } else {
                    const _taxIdNumber = find13DigitNumber(_customer.addressLineOne)
                    if (_taxIdNumber) {
                      _customer.taxId = _taxIdNumber

                      if (_customer.taxId.length !== 13 || isNaN(_customer.taxId)) {
                        _customer.taxNumberType = 'CCPT'
                      } else {
                        if (_branchObject?.StringValue) {
                        _customer.taxNumberType = 'TXID'
                        
                        if (isNaN(_branchObject?.StringValue)) {
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'Head Office'
                        } else {
                          const _branchNumber = String(_branchObject?.StringValue).padStart(5, "0")
                          if (_branchNumber !== "00000") {
                            _customer.branchNumber = _branchNumber
                            _customer.branchText = `Branch ${_branchNumber}`
                          } else {
                            _customer.branchNumber = '00000'
                            _customer.branchText = 'Head Office'
                          }
                        }
                      } else if (hasSpecificValue(_customer, 'head office')) {
                          _customer.taxNumberType = 'TXID'
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'Head Office'
                        } else if (hasSpecificValue(_customer, 'สำนักงานใหญ่')) {
                          _customer.taxNumberType = 'TXID'
                          _customer.branchNumber = '00000'
                          _customer.branchText = 'สำนักงานใหญ่'
                        } else if (hasSpecificValue(_customer, 'สาขา')) {
                          _customer.taxNumberType = 'TXID'
                          
                          const _value = getValue(_customer, 'สาขา')
                          const matchBranch = findValueNextToBranch(_value)
                          if (matchBranch) {
                            const _branchNumber = String(matchBranch).padStart(5, "0")

                            _customer.branchNumber = _branchNumber
                            _customer.branchText = `สาขา ${_branchNumber}`
                          } else {
                            _customer.branchNumber = '00000'
                            _customer.branchText = 'สำนักงานใหญ่'
                          }
                        } else if (hasSpecificValue(_customer, 'branch')) {
                          _customer.taxNumberType = 'TXID'
                          
                          const _value = getValue(_customer, 'branch')
                          const matchBranch = findValueNextToBranch(_value)
                          if (matchBranch) {
                            const _branchNumber = String(matchBranch).padStart(5, "0")

                            _customer.branchNumber = _branchNumber
                            _customer.branchText = `Branch ${_branchNumber}`
                          } else {
                            _customer.branchNumber = '00000'
                            _customer.branchText = 'Head Office'
                          }
                        } else {
                          _customer.taxNumberType = 'NIDN'
                        }
              
                      }
                    }
                  }
                }

                let _note = ''
                if (selectedItems[i].note.length <= 500) {
                  _note = selectedItems[i].note
                }

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        amountTotal: selectedItems[i].amountTotal * 1,
                        company: {
                          ...company,
                          includeVat: selectedItems[i].includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        dueDate: _dueDate,
                        dueDateBE: _dueDateBE,
                        dueDateCE: _dueDateCE,
                        discount: _discount * 1,
                        grandTotal: selectedItems[i].grandTotal * 1,
                        grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                        grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        items: _items,
                        reference: _reference,
                        note: _note,
                        number: selectedItems[i].number,
                        percentVat: registerVat ? percentVat : 0,
                        receivedBy: createdBy,
                        createdBy: createdBy,
                        vatTotal: selectedItems[i].vatTotal * 1,
                        reIssue: false,
                        includeVat: selectedItems[i].includeVat,
                        extraAttributes: selectedItems[i].extraAttributes
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId,
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = await setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'cid': companyId,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('quickBook.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('quickBook.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('quickBook.createdBy')}
                  description={t('quickBook.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('quickBook.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('quickBook.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('quickBook.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('quickBook.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('quickBook.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('quickBook.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('quickBook.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('quickBook.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('quickBook.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('quickBook.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);

                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }} 
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                >
                  <CommandBar
                    items={commandBarItems}
                    overflowItems={overflowItems}
                  />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t('quickBook.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);
                      }
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || isCreateDocument || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('quickBook.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t('quickBook.loading')} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default QuickBooks;
